import React from 'react';
import useSWR, { mutate as globalMutate } from 'swr';
import useSWRInfinite, { SWRInfiniteKeyLoader, SWRInfiniteResponse } from 'swr/infinite';
import { apiRequest, fetcher } from '../lib';

export const DEFAULT_TASK_LIMIT = 25;

export const selectTasksUrl = (workspaceId: string, taskId?: string) => {
  const url = workspaceId ? `/v1/workspaces/${workspaceId}/tasks` : '';

  if (url && taskId) {
    return `${url}/${taskId}`;
  }

  return url;
};

export const getStableTasksUrl = (
  workspaceId: string,
  options: {
    cursor?: string | null;
    filter?: {
      status?: string;
      assignee_id?: string;
    };
  }
) => {
  if (!workspaceId) return null;

  const params = new URLSearchParams();
  if (options?.filter?.status && options.filter.status !== 'all') {
    params.append('filter[status]', options.filter.status);
  }
  if (options?.filter?.assignee_id && options.filter.assignee_id !== 'all') {
    params.append('filter[assignee_id]', options.filter.assignee_id);
  }
  params.append('limit', String(DEFAULT_TASK_LIMIT));
  if (options?.cursor) {
    params.append('cursor', options.cursor);
  }
  params.append('sort', '-created_at');

  return `${selectTasksUrl(workspaceId)}?${params}`;
};

export const buildTasksMutate = (workspaceId: string, taskId?: string) => {
  const url = selectTasksUrl(workspaceId);
  const show_url = taskId && selectTasksUrl(workspaceId, taskId);

  return () => {
    show_url && globalMutate(show_url);
    return globalMutate(url);
  };
};

export const useTasks = (workspaceId: string, payload = {}) => {
  const url = selectTasksUrl(workspaceId) + `?${new URLSearchParams(payload)}`;

  const {
    data: task,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    tasks: task?.data,
    meta: task?.meta,
    links: task?.links,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useTask = (workspaceId: string, taskId: string) => {
  const url = selectTasksUrl(workspaceId, taskId);

  const {
    data: task,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    task: task?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

interface InfiniteTasksResponse
  extends Omit<
    SWRInfiniteResponse<Groupthink.CursorPaginatedResponse<Groupthink.TaskResource>>,
    'data'
  > {
  tasks: Groupthink.TaskResource[];
  hasMorePages: boolean;
}

export const useInfiniteTasks = (
  workspaceId: string,
  options?: {
    filter?: {
      status?: string;
      assignee_id?: string;
    };
    status?: string;
    assignee?: string;
    useRealtimeMutate?: Groupthink.RealtimeMutateHandler;
    useRealtimeCollection?: Groupthink.RealtimeCollectionHandler<Groupthink.TaskResource>;
  }
): InfiniteTasksResponse => {
  const { useRealtimeCollection, useRealtimeMutate } = options || {};

  const url = getStableTasksUrl(workspaceId, { filter: options?.filter });
  const getKey: SWRInfiniteKeyLoader = (
    pageIndex: number,
    previousPageData: Groupthink.CursorPaginatedResponse<Groupthink.TaskResource> | null
  ) => {
    if (pageIndex === 0) {
      const url = getStableTasksUrl(workspaceId, { filter: options?.filter });
      return url;
    }

    if (previousPageData && !previousPageData.meta?.next_cursor) return null;

    const url = getStableTasksUrl(workspaceId, {
      cursor: previousPageData?.meta?.next_cursor,
      filter: options?.filter,
    });

    return url;
  };

  const { data, error, size, setSize, isValidating, isLoading, mutate } = useSWRInfinite<
    Groupthink.CursorPaginatedResponse<Groupthink.TaskResource>
  >(getKey, fetcher, {
    keepPreviousData: true,
    revalidateFirstPage: false,
  });

  const hasMorePages = Boolean(data && data[data.length - 1]?.meta?.next_cursor);

  const tasks = React.useMemo(() => {
    if (!data) return [];

    const taskMap = new Map<string, Groupthink.TaskResource>();

    data.forEach((page) => {
      if (!page?.data) return;
      page.data.forEach((task) => {
        taskMap.set(task.id, task);
      });
    });

    return Array.from(taskMap.values());
  }, [data]);

  useRealtimeMutate?.('TaskCreated', workspaceId ? `workspaces.${workspaceId}` : null, url || null);
  useRealtimeMutate?.('TaskUpdated', workspaceId ? `workspaces.${workspaceId}` : null, url || null);

  useRealtimeCollection?.(
    'TaskCreated',
    workspaceId ? `workspaces.${workspaceId}` : null,
    mutate,
    url,
    null,
    true
  );
  useRealtimeCollection?.(
    'TaskUpdated',
    workspaceId ? `workspaces.${workspaceId}` : null,
    mutate,
    url,
    null,
    true
  );

  return {
    tasks,
    isLoading,
    isValidating,
    hasMorePages,
    size,
    setSize,
    error,
    mutate,
  };
};

export const createTask = (
  workspaceId: string,
  { setErrors, setIsCreating, onSuccess, payload }
) => {
  const mutate = buildTasksMutate(workspaceId);
  const url = selectTasksUrl(workspaceId);

  return apiRequest(url, mutate, 'POST', {
    setErrors,
    setLoading: setIsCreating,
    payload,
    onSuccess,
  });
};

export const updateTask = (
  workspaceId: string,
  taskId: string,
  { setErrors = () => {}, setIsUpdating = () => {}, onSuccess = () => {}, payload = {} } = {}
) => {
  const mutate = buildTasksMutate(workspaceId, taskId);
  const url = selectTasksUrl(workspaceId, taskId);

  return apiRequest(url, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};
