/**
 * Handles in-app as well as native notifications for the Next.js and Electron apps.
 */
import { isElectron, useUser } from '@groupthinkai/groupthink';
import { ChatBubbleOutline as ChatBubbleOutlineIcon } from '@mui/icons-material';
import { Alert, Avatar, Badge, Box, Link, Stack, Typography } from '@mui/joy';
import markdownToTxt from 'markdown-to-txt';
import { useRouter } from 'next/router';
import { CustomContentProps, SnackbarContent, SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';

/**
 * Custom props that are sent to the SnackbarProvider for the notification variant.
 */
declare module 'notistack' {
  interface VariantOverrides {
    notification: {
      body?: string;
      destination?: string;
      icon?: string;
      medium?: 'chat' | string;
      avatar?: string;
      from?: string;
    };
  }
}

/**
 * The payload of a notification that is received from the API.
 */
interface NotificationPayload {
  id: string;
  title: string;
  body?: string;
  destination?: string;
  icon?: string;
  content?: string;
  from?: string;
  medium?: 'chat' | string;
}

const NotificationContext = React.createContext<undefined>(undefined);

type NotificationProviderProps = { children: React.ReactNode };

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const { user } = useUser('me');
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  /**
   * Handle a notification payload by displaying a notification to the user in-app or sending it to the OS.
   *
   * Next.js:
   * - If the tab is visible and at the destination, skip the notification.
   * - If the tab is visible but not at the destination, show the notification in-app.
   * - If the tab is not visible, show the notification in the OS.
   *
   * Electron:
   * - If the app is in the foreground and at the destination, skip the notification.
   * - If the app is in the foreground but not at the destination, show the notification in-app.
   * - If the app is not in the foreground, show the notification in the OS.
   *
   * TODO: Future work - let API know when the web app or desktop app is in the foreground, so it can decide whether to send a notification to the mobile app or not.
   */
  const handleNotification = React.useCallback(
    async (notificationPayload: NotificationPayload) => {
      const cleanedUpDestination = notificationPayload.destination?.split('?')[0];

      if (isElectron) {
        /** ---------- Electron ---------- */
        const focusedWindowPath = await window.electron.getFocusedWindowPath();
        const cleanedUpPath = focusedWindowPath?.split('?')[0];

        if (focusedWindowPath === cleanedUpDestination || cleanedUpPath === cleanedUpDestination) {
          // Skip the notification if the app is in the foreground and at the destination
          return;
        } else if (cleanedUpPath) {
          displayInAppNotification(notificationPayload);
          return;
        } else {
          window.electron.showNotification({
            title: notificationPayload.title ?? 'Notification',
            body: markdownToTxt(notificationPayload.body),
            destination: notificationPayload.destination,
          });
          return;
        }
      } else {
        /** ---------- Next.js ---------- */
        const currentPath = window.location.pathname;
        const cleanedUpPath = currentPath.split('?')[0];
        const isTabVisible = document.visibilityState === 'visible';

        if (
          isTabVisible &&
          (currentPath === cleanedUpDestination || cleanedUpPath === cleanedUpDestination)
        ) {
          // Skip the notification if the tab is visible and at the destination
          return;
        } else if (isTabVisible) {
          displayInAppNotification(notificationPayload);
          return;
        } else if ('Notification' in window && Notification.permission === 'granted') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
          const { body, icon, ...payload } = notificationPayload;
          const browserNotification = new Notification(
            notificationPayload.title ?? 'Notification',
            {
              icon: '/gt_icon.png',
              badge: '/gt_monochrome.png',
              body: markdownToTxt(body),
              ...payload,
            }
          );

          browserNotification.onclick = function () {
            if (notificationPayload?.destination) {
              router.push(notificationPayload.destination);
            }
            browserNotification.close();
            window.parent.focus();
          };
        } else {
          return;
        }
      }
    },
    [router]
  );

  /**
   * Use the SnackbarProvider to display a notification in-app.
   */
  const displayInAppNotification = (notification: NotificationPayload) => {
    enqueueSnackbar(notification.title ?? 'Notification', {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      variant: 'notification',
      body: notification.content,
      from: notification.from,
      destination: notification.destination,
      medium: 'chat',
      avatar: notification.icon,
    });
  };

  /**
   * Request notification permissions when the component mounts.
   */
  React.useEffect(() => {
    if (isElectron) {
      // There is no API for requesting notification permissions in Electron. The OS determines whether the user needs to be prompted when a notification is sent.
      return;
    }

    if (typeof window != 'undefined' && 'Notification' in window) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then((permission) => {
          if (permission !== 'granted') {
            console.debug('[NotificationProvider] User declined or dismissed ', {
              permission,
            });
            return;
          }
        });
      }
    }
  }, []);

  /**
   * Subscribe to the user's private notifications channel.
   */
  React.useEffect(() => {
    if (!user || !window?.Echo) {
      return;
    }
    const channelName = 'users.' + user.id + '.notifications';

    // Necessary to avoid multiple subscriptions
    if (window.Echo.connector.channels[channelName]) {
      window.Echo.leave(channelName);
    }

    const channel = window.Echo.private(channelName);

    const eventName = 'notification';
    channel.stopListening(eventName);

    channel.notification(handleNotification);

    return () => {
      window.Echo.leave(channelName);
    };
  }, [user, handleNotification]);

  const value = React.useMemo(() => undefined, []);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

interface NotificationSnackbarProps extends CustomContentProps {
  body: string;
  destination?: string;
  avatar?: string;
  medium?: 'chat' | string;
  from: string;
}

/**
 * A custom notification component that displays in-app notifications.
 */
const NotificationSnackbar = React.forwardRef<HTMLDivElement, NotificationSnackbarProps>(
  (props, ref) => {
    const { message, body, destination, avatar, medium, from, id: key } = props;
    const { closeSnackbar } = useSnackbar();

    const router = useRouter();
    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      closeSnackbar(key);
      if (destination) {
        router.push(destination);
      }
    };

    const icon = avatar && (
      <Box ml={0.75}>
        <Badge
          variant="plain"
          badgeContent={medium === 'chat' ? <ChatBubbleOutlineIcon /> : null}
          size="sm"
          invisible={medium !== 'chat'}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Avatar size="sm" src={avatar} srcSet={avatar} sx={{ mt: 0.5 }} />
        </Badge>
      </Box>
    );

    return (
      <Link
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
        onClick={handleLinkClick}>
        <Alert
          ref={ref}
          sx={{
            alignItems: 'flex-start',
            maxWidth: {
              xs: '100vw',
              md: '35vw',
            },
          }}
          startDecorator={icon}
          variant="soft"
          color="neutral">
          <SnackbarContent>
            <Stack>
              <Typography level="title-sm" fontWeight="lg">
                {message}
              </Typography>
              {body && (
                <Typography
                  level="body-sm"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}>
                  {from && <Typography fontWeight={'lg'}>{from}: </Typography>}
                  {markdownToTxt(body)}
                </Typography>
              )}
            </Stack>
          </SnackbarContent>
        </Alert>
      </Link>
    );
  }
);
NotificationSnackbar.displayName = 'NotificationSnackbar';

/**
 * It's necessary to wrap the app in the SnackbarProvider to use the custom notification component.
 */
export const NotificationSnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SnackbarProvider
      Components={{
        notification: NotificationSnackbar,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
      {children}
    </SnackbarProvider>
  );
};
