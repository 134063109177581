import { fetcher, apiRequest } from '../lib';
import useSWR, { mutate as globalMutate } from 'swr';

export const useFolder = (workspaceId?: string, id?: string) => {
  const url = `/v1/workspaces/${workspaceId}/folders/${id}`;

  const {
    data: folder,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'folder.show'>>(
    () => (id && id !== 'all' ? `${url}` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const updateFolder = async <RouteName = 'folder.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    const url = `/v1/workspaces/${workspaceId}/folders/${id}`;
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  //TODO allow pinning a folder?

  return {
    folder: folder?.data,
    isLoading,
    isError: error,
    mutate,
    updateFolder,
  };
};

export const deleteFolder = (
  workspaceId: string,
  folderId: string,
  { setErrors, setIsDeleting, onSuccess }
) => {
  const url = `/v1/workspaces/${workspaceId}/folders/${folderId}`;
  const mutate = () => globalMutate(`/v1/workspaces/${workspaceId}/folders`);

  return apiRequest(url, mutate, 'DELETE', {
    setErrors,
    setLoading: setIsDeleting,
    onSuccess,
  });
};

export const updateLinkOrder = async <RouteName = 'folder.update'>(
  workspaceId: string,
  folderId: string,
  linkId: string | bigint,
  { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
) => {
  const url = `/v1/workspaces/${workspaceId}/folders/${folderId}/links/${linkId}`;
  const mutate = () => globalMutate(`/v1/workspaces/${workspaceId}/folders`);

  apiRequest<RouteName>(url, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

export const addToFolder = async <RouteName = 'folder.add'>(
  workspaceId: string,
  { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
) => {
  const url = `/v1/workspaces/${workspaceId}/folders/add`;
  const mutate = () => globalMutate(`/v1/workspaces/${workspaceId}/folders`);
  apiRequest<RouteName>(url, mutate, 'POST', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

export const removeFromFolder = async <RouteName = 'folder.remove'>(
  workspaceId: string,
  folderId: string,
  { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
) => {
  const url = `/v1/workspaces/${workspaceId}/folders/${folderId}/remove`;
  const mutate = () => globalMutate(`/v1/workspaces/${workspaceId}/folders`);
  apiRequest<RouteName>(url, mutate, 'POST', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

export const useFolders = (workspaceId: string) => {
  const url = workspaceId ? `/v1/workspaces/${workspaceId}/folders` : null;

  const {
    data: folder,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'folder.index'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  const createFolder = async <RouteName = 'folder.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });
  };

  return {
    folders: folder?.data,
    isLoading,
    isError: error,
    mutate,
    createFolder,
  };
};
