export function isBrowser(): boolean {
  return typeof window !== 'undefined';
}

// export function isElectron(): boolean {
// @ts-ignore - window.electron is injected by electron
// return isBrowser() && window.process?.type === 'renderer';
// }

export const isElectron = process.env.NEXT_PUBLIC_PLATFORM === 'electron';

export const getPlatform = () => {
  if (typeof window === 'undefined') return 'server';
  if (!window.navigator) return 'unknown';

  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('mac') !== -1) return 'macos';
  if (userAgent.indexOf('win') !== -1) return 'windows';
  if (userAgent.indexOf('linux') !== -1) return 'linux';
  if (/iphone|ipad|ipod/.test(userAgent)) return 'ios';
  if (userAgent.indexOf('android') !== -1) return 'android';

  return 'unknown';
};

export const isMac = () => getPlatform() === 'macos';
export const isWindows = () => getPlatform() === 'windows';
export const isLinux = () => getPlatform() === 'linux';
export const isMobile = () => getPlatform() === 'ios' || getPlatform() === 'android';
