/// <reference path="../groupthink-js.d.ts" />

import { apiRequest } from '../lib';

export const useInstallations = <RouteName = 'installation.store'>() => {
  // Must obtain global mutate from useSWRConfig, otherwise it won't use our custom cache provider

  const createInstallation = ({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/users/me/installations`, null, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateInstallation = <RouteName = 'installation.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/users/me/installations/${id}`, null, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      onSuccess,
      payload,
    });

  const deleteInstallation = <RouteName = 'installation.delete'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/users/me/installations/${id}`, null, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    createInstallation,
    updateInstallation,
    deleteInstallation,
  };
};
