export type ResourceInaccessibleErrorResourceType = 'agenda' | 'document' | 'room' | 'resource';

const errorTypes = {
  403: {
    title: 'Access Denied',
    message: `You don't have permission to view this {resourceType}. Please contact the owner for assistance.`,
    level: 'warning',
    allowsRetry: true,
  },
  404: {
    title: `{resourceTypeTitle} Not Found`,
    message: `We couldn't find this {resourceType}. Please check the URL and try again.`,
    level: 'danger',
    allowsRetry: false,
  },
  default: {
    title: `{resourceTypeTitle} Error`,
    message: `We encountered an error loading this {resourceType}. Please try again later or contact support.`,
    level: 'neutral',
    allowsRetry: true,
  },
};

export const useResourceInaccessibleError = (
  error: { response: { status: number } },
  resourceType: ResourceInaccessibleErrorResourceType = 'resource'
) => {
  const resourceTypeTitle = resourceType.charAt(0).toUpperCase() + resourceType.slice(1);

  const errorCode = error?.response?.status;
  const errorType = errorTypes[errorCode] || errorTypes.default;

  const title = errorType.title.replace('{resourceTypeTitle}', resourceTypeTitle);
  const message = errorType.message.replace('{resourceType}', resourceType);

  return {
    isReady: error?.response?.status !== undefined,
    title,
    message,
    level: errorType.level,
    allowsRetry: errorType.allowsRetry,
    name: resourceTypeTitle,
  };
};
