import useSWR, { mutate as globalMutate } from 'swr';
import { apiRequest, fetcher } from '../lib';

export const useMonitorsUrl = (workspaceId: string, assistantId: string, monitorId?: string) => {
  const url =
    workspaceId && assistantId
      ? `/v1/workspaces/${workspaceId}/assistants/${assistantId}/monitors`
      : null;

  if (url && monitorId) {
    return `${url}/${monitorId}`;
  }

  return url;
};

export const useMonitorsMutate = (workspaceId: string, assistantId: string) => {
  const url = useMonitorsUrl(workspaceId, assistantId);
  return () => globalMutate(url);
};

export const useMonitors = (workspaceId: string, assistantId: string) => {
  const url = useMonitorsUrl(workspaceId, assistantId);

  const {
    data: monitor,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    monitors: monitor?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useMonitorCatalog = (
  workspaceId: string,
  assistantId: string,
  connectionId?: string
) => {
  let url = useMonitorsUrl(workspaceId, assistantId);
  if (url) url += '/catalog?connection_id=' + connectionId;

  const {
    data: catalog,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    catalog: catalog?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useMonitor = (workspaceId: string, assistantId: string, monitorId: string) => {
  const url = useMonitorsUrl(workspaceId, assistantId, monitorId);

  const {
    data: monitor,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    monitor: monitor?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const createMonitor = (
  workspaceId: string,
  assistantId: string,
  { setErrors, setIsCreating, onSuccess, payload }
) => {
  const mutate = useMonitorsMutate(workspaceId, assistantId);
  const url = useMonitorsUrl(workspaceId, assistantId);

  return apiRequest(url, mutate, 'POST', {
    setErrors,
    setLoading: setIsCreating,
    payload,
    onSuccess,
  });
};

export const updateMonitor = (
  workspaceId: string,
  assistantId: string,
  monitorId: string,
  { setErrors, setIsUpdating, onSuccess, payload }
) => {
  const mutate = useMonitorsMutate(workspaceId, assistantId);
  const url = useMonitorsUrl(workspaceId, assistantId, monitorId);

  return apiRequest(url, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

export const deleteMonitor = (
  workspaceId: string,
  assistantId: string,
  monitorId: string,
  { setIsDeleting, onSuccess }
) => {
  const mutate = useMonitorsMutate(workspaceId, assistantId);
  const url = useMonitorsUrl(workspaceId, assistantId, monitorId);

  return apiRequest(url, mutate, 'DELETE', {
    setLoading: setIsDeleting,
    onSuccess,
  });
};
