import useSWR, { mutate as globalMutate } from 'swr';
import { apiRequest, fetcher } from '../lib';

export const useAssistantsUrl = (workspaceId: string, assistantId?: string) => {
  const url = workspaceId ? `/v1/workspaces/${workspaceId}/assistants` : '';

  if (url && assistantId) {
    return `${url}/${assistantId}`;
  }

  return url;
};

export const useAssistantsMutate = (workspaceId: string) => {
  const url = useAssistantsUrl(workspaceId);
  return () => globalMutate(url);
};

export const useAssistants = (workspaceId: string, type: string = 'workspace') => {
  const url = useAssistantsUrl(workspaceId) + `?type=${type}`;

  const {
    data: assistant,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    assistants: assistant?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useAssistant = (workspaceId: string, assistantId: string) => {
  const url = useAssistantsUrl(workspaceId, assistantId);

  const {
    data: assistant,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'assistant.show'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  const inviteUser = <RouteName = 'assistant.addUser'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    const inviteUrl = `${url}/users`;
    apiRequest<RouteName>(inviteUrl, mutate, 'POST', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const removeUser = <RouteName = 'assistant.removeUser'>(
    userId: string,
    {
      setErrors,
      setIsRemoving,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsRemoving?: (isRemoving: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(`${url}/users/${userId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsRemoving,
      onSuccess,
    });

  return {
    // @ts-ignore
    assistant: assistant?.data,
    inviteUser,
    removeUser,
    isLoading,
    isError: error,
    mutate,
  };
};

export const createAssistant = (
  workspaceId: string,
  { setErrors, setIsCreating, onSuccess, payload }
) => {
  const mutate = useAssistantsMutate(workspaceId);
  const url = useAssistantsUrl(workspaceId);

  return apiRequest(url, mutate, 'POST', {
    setErrors,
    setLoading: setIsCreating,
    payload,
    onSuccess,
  });
};

export const updateAssistant = (
  workspaceId: string,
  assistantId: string,
  { setErrors, setIsUpdating, onSuccess, payload }
) => {
  const mutate = useAssistantsMutate(workspaceId);
  const url = useAssistantsUrl(workspaceId, assistantId);

  return apiRequest(url, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

export const deleteAssistant = (
  workspaceId: string,
  assistantId: string,
  { setIsDeleting, onSuccess }
) => {
  const mutate = useAssistantsMutate(workspaceId);
  const url = useAssistantsUrl(workspaceId, assistantId);

  return apiRequest(url, mutate, 'DELETE', {
    setLoading: setIsDeleting,
    onSuccess,
  });
};
