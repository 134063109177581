/// <reference path="../groupthink-js.d.ts" />

import React from 'react';
import { axios, fetcher, apiRequest } from '../lib';
import useSWR, { useSWRConfig, mutate as globalMutate } from 'swr';
import { AxiosRequestConfig } from 'axios';
import useSWRInfinite, { SWRInfiniteKeyLoader, SWRInfiniteResponse } from 'swr/infinite';
export const DEFAULT_THREAD_LIMIT = 25;
export const DEFAULT_MESSAGE_LIMIT = 25;

export const enum ThreadableContext {
  Agenda = 'agenda',
  Document = 'document',
  Room = 'room',
  Task = 'task',
}
export const enum Threadable {
  Agendas = ThreadableContext.Agenda + 's',
  Documents = ThreadableContext.Document + 's',
  Rooms = ThreadableContext.Room + 's',
  Tasks = ThreadableContext.Task + 's',
}

const prefixContextMap = {
  AG: ThreadableContext.Agenda,
  DO: ThreadableContext.Document,
  RM: ThreadableContext.Room,
  TK: ThreadableContext.Task,
};

const threadableContextMap = {
  [ThreadableContext.Agenda]: Threadable.Agendas,
  [ThreadableContext.Document]: Threadable.Documents,
  [ThreadableContext.Room]: Threadable.Rooms,
  [ThreadableContext.Task]: Threadable.Tasks,
};

const DEFAULT_THREADABLE_CONTEXT = ThreadableContext.Agenda;

const getThreadableContextFromId = (threadableId?: string) => {
  if (!threadableId) return DEFAULT_THREADABLE_CONTEXT;
  const prefix = threadableId.slice(0, 2);
  return prefixContextMap[prefix];
};

const getThreadableFromContext = (threadableContext?: ThreadableContext): Threadable | null => {
  if (!threadableContext) return threadableContextMap[DEFAULT_THREADABLE_CONTEXT];
  return threadableContextMap[threadableContext];
};

export const getThreadableFromId = (threadableId?: string): Threadable | null => {
  return getThreadableFromContext(getThreadableContextFromId(threadableId));
};

export const getThreadableIdFieldFromId = (threadableId?: string) => {
  if (!threadableId) return DEFAULT_THREADABLE_CONTEXT + '_id';
  return getThreadableContextFromId(threadableId) + '_id';
};

export const useThread = (
  context?: ThreadableContext,
  threadableId?: string,
  id?: string,
  options?: {
    useRealtimeMutate?: Groupthink.RealtimeMutateHandler;
  }
) => {
  const { useRealtimeMutate } = options || {};

  const url =
    Boolean(threadableId) && Boolean(id) && id !== 'new'
      ? `/v1/${getThreadableFromContext(context)}/${threadableId}/threads/${id}`
      : false;

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'thread.show_0'>
  >(url, fetcher, {
    keepPreviousData: true,
  });

  const { cache } = useSWRConfig();

  useRealtimeMutate?.(
    'ThreadUpdated',
    !threadableId ? null : `${getThreadableFromContext(context)}.${threadableId}`,
    url
  );
  useRealtimeMutate?.('MessageSent', !id ? null : `threads.${id}.messages`, url);
  useRealtimeMutate?.('MessageReactedTo', !id ? null : `threads.${id}.messages`, url);

  // (1 of 2) -- keep this method in sync with useThreads' version
  const markThreadAsRead = <RouteName = 'thread.mark_as_read'>(
    message_id: string,
    {
      setErrors,
      setIsMarkingRead,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsMarkingRead?: (isMarkingRead: boolean) => void;
    }
  ) =>
    url &&
    apiRequest<RouteName>(`${url}/mark_as_read`, mutate, 'POST', {
      setErrors,
      setLoading: setIsMarkingRead,
      payload: { message_id },
      onSuccess: (data) => {
        // when we mark as read we want to mutate all the base rooms endpoints
        // to update the sidebar
        Array.from(cache.keys()).forEach((key) => {
          if (/\/rooms/.test(key) || /\/unread/.test(key)) globalMutate(key);
        });

        mutate(); // this is thread mutate
        onSuccess?.(data);
      },
    });

  return {
    thread: data !== undefined && 'data' in data ? data.data : undefined,
    isLoading,
    isError: error,
    mutate,
    markThreadAsRead,
  };
};

export const getThreadsUrl = (threadableId?: string) => {
  if (!threadableId) return null;
  const threadable = getThreadableFromId(threadableId);
  if (!threadable) return '';
  return `/v1/${threadable}/${threadableId}/threads`;
};

// Export a useThreads key builder for re-use when sending a message optimistically
export const getStableThreadsUrl = (threadableId?: string, cursor?: string | null) => {
  if (!threadableId) return null;
  const threadable = getThreadableFromId(threadableId);
  if (!threadable) return '';

  let url = `${getThreadsUrl(threadableId)}?limit=${DEFAULT_THREAD_LIMIT}&sort=-created_at`;
  if (cursor) {
    url += `&cursor=${cursor}`;
  }
  return url;
};

export const useThreads = (
  threadableId?: string,
  options?: {
    useRealtimeMutate?: Groupthink.RealtimeMutateHandler;
    useRealtimeCollection?: Groupthink.RealtimeCollectionHandler<Groupthink.ThreadResource>;
  }
) => {
  const { useRealtimeCollection, useRealtimeMutate } = options || {};

  const url = getStableThreadsUrl(threadableId);

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'thread.index_0'>
  >(() => url ?? false, fetcher, {
    keepPreviousData: true,
  });

  const { cache } = useSWRConfig();

  const createThread = <RouteName = 'thread.store'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    const threadsUrl = getThreadsUrl(threadableId);
    if (threadsUrl) {
      return apiRequest<RouteName>(threadsUrl, mutate, 'POST', {
        setErrors,
        setLoading: setIsCreating,
        payload,
        onSuccess,
      });
    }

    return Promise.reject(new Error('Threadable ID is required to create a thread.'));
  };

  const updateThread = <RouteName = 'thread.update'>(
    threadId: string,
    { setErrors, setIsCreating, onSuccess, payload }: Groupthink.CreateOperationOptions<RouteName>
  ) => {
    const threadsUrl = getThreadsUrl(threadableId);
    const threadUrl = `${threadsUrl}/${threadId}`;

    if (threadsUrl && threadId) {
      return apiRequest<RouteName>(threadUrl, mutate, 'PUT', {
        setErrors,
        setLoading: setIsCreating,
        payload,
        onSuccess,
      });
    }

    return Promise.reject(
      new Error('Threadable ID and Thread ID are required to update a thread.')
    );
  };

  const deleteThread = async <RouteName = 'thread.destroy'>(
    threadId: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) => {
    const threadsUrl = getThreadsUrl(threadableId);
    const threadUrl = `${threadsUrl}/${threadId}`;

    if (threadsUrl && threadId) {
      return apiRequest<RouteName>(threadUrl, mutate, 'DELETE', {
        setErrors,
        setLoading: setIsDeleting,
        onSuccess,
      });
    }

    return Promise.reject(
      new Error('Threadable ID and Thread ID are required to delete a thread.')
    );
  };

  // (2 of 2) -- keep this method in sync with useThread's version
  const markThreadAsRead = <RouteName = 'thread.mark_as_read'>(
    threadableType: string,
    threadableId: string,
    threadId: string,
    messageId: string,
    params: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsMarkingRead?: (isMarkingRead: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(
      `/v1/${threadableType.toLowerCase()}s/${threadableId}/threads/${threadId}/mark_as_read`,
      null,
      'POST',
      {
        setErrors: params?.setErrors,
        setLoading: params?.setIsMarkingRead,
        payload: { message_id: messageId },
        onSuccess: (data) => {
          // when we mark as read we want to mutate all the base rooms endpoints
          // to update the sidebar
          Array.from(cache.keys()).forEach((key) => {
            if (/\/rooms$/.test(key)) globalMutate(key);
          });
          mutate(); // this is threads mutate
          params?.onSuccess?.(data);
        },
      }
    );

  const threadable = getThreadableFromId(threadableId);

  useRealtimeMutate?.(
    'ThreadCreated',
    threadableId ? `${threadable}.${threadableId}` : null,
    url || null
  );
  useRealtimeMutate?.(
    'ThreadUpdated',
    threadableId ? `${threadable}.${threadableId}` : null,
    url || null
  );
  useRealtimeCollection?.(
    'ThreadCreated',
    threadableId ? `${threadable}.${threadableId}` : null,
    mutate,
    url,
    null,
    true
  );
  useRealtimeCollection?.(
    'ThreadUpdated',
    threadableId ? `${threadable}.${threadableId}` : null,
    mutate,
    url,
    null,
    true
  );
  useRealtimeMutate?.(
    'MessageSent',
    threadableId ? `${threadable}.${threadableId}.messages` : null,
    url || null
  );
  useRealtimeCollection?.(
    'MessageSent',
    threadableId ? `${threadable}.${threadableId}.messages` : null,
    mutate,
    url,
    null,
    true
  );

  return {
    threads: data !== undefined && 'data' in data ? data.data : undefined,
    isLoading,
    isError: error,
    mutate,
    createThread,
    updateThread,
    deleteThread,
    markThreadAsRead,
  };
};

interface InfiniteThreadsResponse
  extends Omit<
    SWRInfiniteResponse<Groupthink.CursorPaginatedResponse<Groupthink.ThreadResource>>,
    'data'
  > {
  threads: Groupthink.ThreadResource[];
  hasMorePages: boolean;
  updateMessage: <RouteName = 'message.update'>(
    threadId: string,
    id: string,
    options: Groupthink.UpdateOperationOptions<RouteName>
  ) => Promise<void>;
  sendMessage: <RouteName = 'message.send'>(
    threadableId: string,
    threadId: string,
    options: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsSending?: (isSending: boolean) => void;
      content: string;
      user: Groupthink.UserResource;
      messages?: Groupthink.MessageResource[];
      attachments?: string[];
    }
  ) => Promise<void>;
}

const mergeExistingDataWithNewMessage = (
  currentData: Groupthink.CursorPaginatedResponse<Groupthink.ThreadResource>[],
  message: Groupthink.MessageResource,
  threadId: string,
  user: Groupthink.User
) => {
  if (!currentData?.length) return currentData;

  if (threadId === 'new') {
    let threadable_type = 'Agenda';
    // @ts-ignore
    if (message.room_id) {
      threadable_type = 'Room';
      // @ts-ignore
    } else if (message.document_id) {
      threadable_type = 'Document';
    }

    const newThread: Groupthink.ThreadResource = {
      id: threadId,
      name: 'New Thread',
      message_count: 1,
      summary: null,
      has_unread: false,
      oldest_message: message,
      created_by: user,
      created_at: new Date().toISOString(),
      updated_by: user,
      updated_at: new Date().toISOString(),
      threadable_type: threadable_type,
      threadable_id:
        // @ts-ignore
        message.room_id || message.document_id || message.agenda_id || message.task_id || '',
    };

    return [
      {
        ...currentData[0],
        data: [newThread, ...currentData[0].data],
      },
      ...currentData.slice(1),
    ];
  }
  return currentData.map((page) => ({
    ...page,
    data: page.data.map((thread) =>
      thread.id === threadId
        ? {
            ...thread,
            message_count: thread.message_count ?? 0 + 1,
            updated_at: new Date().toISOString(),
            updated_by: user,
            oldest_message: message,
          }
        : thread
    ),
  }));
};

export const useUnreadThreads = (threadableId?: string) => {
  const url = threadableId ? getThreadsUrl(threadableId) + '/unread' : null;
  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'thread.unreadThreads_0'>
  >(url, fetcher, {
    keepPreviousData: true,
  });

  return {
    unreadThreadIds: data?.unread_threads,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useInfiniteThreads = (
  threadableId?: string,
  options?: {
    useRealtimeMutate?: Groupthink.RealtimeMutateHandler;
    useRealtimeCollection?: Groupthink.RealtimeCollectionHandler<Groupthink.ThreadResource>;
  }
): InfiniteThreadsResponse => {
  const { useRealtimeCollection, useRealtimeMutate } = options || {};

  const url = getStableThreadsUrl(threadableId);

  const getKey: SWRInfiniteKeyLoader = (
    pageIndex: number,
    previousPageData: Groupthink.CursorPaginatedResponse<Groupthink.ThreadResource> | null
  ) => {
    if (pageIndex === 0) return getStableThreadsUrl(threadableId);

    if (previousPageData && !previousPageData.meta?.next_cursor) return null;

    return getStableThreadsUrl(threadableId, previousPageData?.meta?.next_cursor);
  };

  const { data, error, size, setSize, isValidating, isLoading, mutate } = useSWRInfinite<
    Groupthink.CursorPaginatedResponse<Groupthink.ThreadResource>
  >(getKey, fetcher, {
    keepPreviousData: true,
    revalidateFirstPage: false,
  });

  const hasMorePages = Boolean(data && data[data.length - 1]?.meta?.next_cursor);

  const threads = React.useMemo(() => {
    if (!data) return [];

    const threadMap = new Map<string, Groupthink.ThreadResource>();

    data.forEach((page) => {
      if (!page?.data) return;
      page.data.forEach((thread) => {
        threadMap.set(thread.id, thread);
      });
    });

    return Array.from(threadMap.values());
  }, [data]);

  const sendMessage = async <RouteName = 'message.send'>(
    threadableId: string,
    threadId: string,
    {
      setErrors,
      setIsSending,
      onSuccess,
      content,
      user,
      attachments,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsSending?: (isSending: boolean) => void;
      content: string;
      user: Groupthink.UserResource;
      attachments?: string[];
    }
  ) => {
    const url = getThreadsUrl(threadableId) + `/${threadId}/messages`;
    const threadable_id_field = getThreadableIdFieldFromId(threadableId);

    // necessary to avoid TypeScript complaining about this being potentially false.
    const messageUrl = url as string;

    setErrors?.({});
    setIsSending?.(true);

    const newMessage = {
      sent_by: user,
      thread_id: threadId,
      [threadable_id_field]: threadableId,
      content: content,
      id: 'new',
      extra: '',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      workspace_id: null,
      classification: null,
      parent_id: null,
      room_id: null,
      reactions: [],
      attachments: [],
    };
    const sendMessageFn = async () =>
      axios(messageUrl, {
        method: 'POST',
        data: { content, attachments },
      }).then((res) => {
        setIsSending?.(false);
        onSuccess?.(res.data);
        return res.data;
      });

    if (threadId && threadId !== 'new') {
      // We're adding a message to an existing thread, so we'll need to revalidate the useMessages key after the message is sent. This is a different key than the useInfiniteThreads key.
      await globalMutate(
        url,
        async () =>
          axios(messageUrl, {
            method: 'POST',
            data: { content, attachments },
          }).then((res) => {
            setIsSending?.(false);
            onSuccess?.(res.data);
            return res.data;
          }),
        {
          optimisticData: (currentData) => {
            const existingMessages = currentData?.data;
            return {
              data:
                existingMessages && existingMessages.length > 0
                  ? [...existingMessages, newMessage]
                  : [newMessage],
            };
          },
          populateCache: (result, currentData) => {
            const remoteMessage = result?.data;
            const existingMessages = currentData?.data;

            return {
              data:
                existingMessages && existingMessages.length > 0
                  ? [...existingMessages, remoteMessage]
                  : [remoteMessage],
            };
          },
          revalidate: false,
          rollbackOnError(error) {
            // If it's timeout abort error, don't rollback
            return error instanceof Error && error.name !== 'AbortError';
          },
        }
      );
    } else {
      // We're adding a message to a new thread, so we'll need to revalidate the useInfiniteThreads key after the message is sent.
      await mutate(sendMessageFn, {
        optimisticData: (currentData) => {
          // @ts-ignore
          return mergeExistingDataWithNewMessage(currentData, newMessage, threadId, user);
        },
        // @ts-ignore
        populateCache: (result, currentData) => {
          // @ts-ignore
          return mergeExistingDataWithNewMessage(currentData, result?.data, threadId, user);
        },
        revalidate: true,
        rollbackOnError(error) {
          // If it's timeout abort error, don't rollback
          return error instanceof Error && error.name !== 'AbortError';
        },
      });
    }
  };

  const updateMessage = async <RouteName = 'message.update'>(
    threadId: string,
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) => {
    const messagesUrl = getThreadsUrl(threadableId) + `/${threadId}/messages`;
    const messageUrl = `${messagesUrl}/${id}`;

    return apiRequest<RouteName>(messageUrl, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    }).then(async () => {
      await globalMutate(url);
      await globalMutate(messagesUrl);
    });
  };

  const threadable = getThreadableFromId(threadableId);
  useRealtimeMutate?.(
    'ThreadCreated',
    threadableId ? `${threadable}.${threadableId}` : null,
    url || null
  );
  useRealtimeMutate?.(
    'ThreadUpdated',
    threadableId ? `${threadable}.${threadableId}` : null,
    url || null
  );

  useRealtimeCollection?.(
    'ThreadCreated',
    threadableId ? `${threadable}.${threadableId}` : null,
    mutate,
    url,
    null,
    true
  );
  useRealtimeCollection?.(
    'ThreadUpdated',
    threadableId ? `${threadable}.${threadableId}` : null,
    mutate,
    url,
    null,
    true
  );

  return {
    threads,
    isLoading,
    isValidating,
    hasMorePages,
    size,
    setSize,
    error,
    mutate,
    sendMessage,
    updateMessage,
  };
};

export const manipulateThreads = async <RouteName>(
  url: string,
  {
    method = 'POST',
    payload,
  }: {
    method?: AxiosRequestConfig['method'];
    payload?: Groupthink.RequestPayload<RouteName> | Record<string, unknown>;
  }
) => {
  await axios(url, {
    method,
    data: payload,
  });
};

/**
 * Takes an array of threads, ideally one that is returned by the useThreads hook, and reconstitutes it with the updated message,
 * or adds/deletes a reaction to/from the message.
 * To be used in the optimisticData and populateCache options of the useThreads mutate function.
 */
export const reconstituteThreads = ({
  user,
  threads,
  threadId,
  messageId,
  addReaction,
  deleteReaction,
  updatedMessage,
}: {
  threads: Groupthink.ThreadResource[] | undefined;
  user?: Groupthink.UserResource;
  threadId?: string;
  messageId?: string;
  addReaction?: Groupthink.MessageReactionResource;
  deleteReaction?: Groupthink.MessageReactionResource;
  updatedMessage?: Groupthink.MessageResource;
}) =>
  threads?.map((thread) => {
    if (thread.id === threadId && thread.oldest_message?.id === messageId) {
      return {
        ...thread,
        oldest_message: updatedMessage ?? {
          // if updatedMessage is provided, use it, otherwise take the existing oldest_message and merge it with the added/removed reaction payload
          ...thread.oldest_message,
          reactions: [
            ...// If we're deleting a reaction, we need to filter it out of the reactions array
            (
              (deleteReaction &&
                thread.oldest_message?.reactions?.filter(
                  (r) => r.content !== deleteReaction.content
                )) ||
              thread.oldest_message?.reactions ||
              []
            )
              // If we're adding a reaction, we need to add it to the reactions array IFF it doesn't already exist
              .concat(
                addReaction &&
                  user &&
                  !thread.oldest_message?.reactions?.some(
                    (r) => r.content === addReaction.content && r.sent_by.id === user.id
                  )
                  ? {
                      ...addReaction,
                      sent_by: user,
                      message_id: messageId,
                    }
                  : []
              ),
          ],
        },
      };
    }

    return thread;
  }) || [];

export const infiniteThreadsWithNewMessage = ({ threadId, threads, newMessage, user }) => {};

export const threadsWithNewMessage = ({ threadId, threads, newMessage, user }) => {
  let newThreads = [...threads];
  if (threadId === 'new') {
    newThreads = [
      ...threads,
      {
        // @ts-ignore
        id: threadId,
        name: 'New Thread',
        message_count: 1,
        has_unread: false,
        oldest_message: newMessage,
        created_by: user,
        created_at: new Date().toISOString(),
        updated_by: user,
        updated_at: new Date().toISOString(),
      },
    ];
  } else {
    newThreads = [
      ...threads.map((thread) => {
        if (thread.id === threadId) {
          const updatedThread = {
            ...thread,
            message_count: (parseInt(thread.message_count) + 1).toString(),
            updated_at: new Date().toISOString(),
            updated_by: user,
          };
          return updatedThread;
        }
        return thread;
      }),
    ];
  }

  return newThreads;
};

export const handleMessageSentEventPayload = ({ mutate, payload, onSuccess }) => {
  if (!payload || !mutate) {
    console.log('[hooks/thread] Missing payload or mutate');
    return;
  }

  try {
    const messageData = typeof payload === 'string' ? JSON.parse(payload) : payload;

    Promise.resolve(
      mutate(
        (currentData) => {
          if (!currentData) {
            return [
              {
                data: [
                  {
                    id: messageData.thread_id,
                    name: 'New Thread',
                    summary: null,
                    message_count: 1,
                    oldest_message: messageData,
                    threadable_type: messageData.threadable_key
                      ? messageData.threadable_key.charAt(0).toUpperCase() +
                        messageData.threadable_key.slice(1)
                      : null,
                    threadable_id: messageData.threadable_id,
                    created_at: messageData.created_at,
                    updated_at: messageData.updated_at,
                    created_by: messageData.sent_by,
                    updated_by: messageData.sent_by,
                  },
                ],
              },
            ];
          }

          if (!Array.isArray(currentData)) {
            return [
              {
                data: [
                  {
                    id: messageData.thread_id,
                    name: 'New Thread',
                    summary: null,
                    message_count: 1,
                    oldest_message: messageData,
                    threadable_type: messageData.threadable_key
                      ? messageData.threadable_key.charAt(0).toUpperCase() +
                        messageData.threadable_key.slice(1)
                      : null,
                    threadable_id: messageData.threadable_id,
                    created_at: messageData.created_at,
                    updated_at: messageData.updated_at,
                    created_by: messageData.sent_by,
                    updated_by: messageData.sent_by,
                  },
                ],
              },
            ];
          }

          let newThread = true;
          const updatedDataArray = currentData.map((page) => {
            if (!page || typeof page !== 'object') {
              return { data: [] };
            }

            if (!page.data || !Array.isArray(page.data)) {
              return { ...page, data: [] };
            }

            const threadIndex = page.data.findIndex(
              (thread) => thread && thread.id === messageData.thread_id
            );

            if (threadIndex >= 0) {
              newThread = false;
              const updatedThreads = [...page.data];
              updatedThreads[threadIndex] = {
                ...updatedThreads[threadIndex],
                message_count: (updatedThreads[threadIndex].message_count || 0) + 1,
              };

              return {
                ...page,
                data: updatedThreads,
              };
            }

            return page;
          });

          if (newThread) {
            return [
              {
                data: [
                  {
                    id: messageData.thread_id,
                    name: 'New Thread',
                    summary: null,
                    message_count: 1,
                    oldest_message: messageData,
                    threadable_type: messageData.threadable_key
                      ? messageData.threadable_key.charAt(0).toUpperCase() +
                        messageData.threadable_key.slice(1)
                      : null,
                    threadable_id: messageData.threadable_id,
                    created_at: messageData.created_at,
                    updated_at: messageData.updated_at,
                    created_by: messageData.sent_by,
                    updated_by: messageData.sent_by,
                  },
                ],
              },
              ...updatedDataArray,
            ];
          }

          return updatedDataArray;
        },
        { revalidate: false, populateCache: true }
      )
    )
      .then(() => {
        onSuccess?.();
      })
      .catch((error) => {
        console.error('[hooks/thread] Mutate error caught:', error);
      });
  } catch (error) {
    console.error('[hooks/thread] Top-level error:', error);
  }
};
