import {
  axios,
  fetcher,
  mutateInternal,
  apiRequest,
  configureAxiosBaseUrl,
  configureAuthorizationHeader,
  configureWithCredentials,
  clearAuthorizationHeader,
} from './client';

import { isBrowser, isElectron, isLinux, isMac, isMobile, isWindows } from './platform';

export {
  axios,
  fetcher,
  mutateInternal,
  apiRequest,
  configureAxiosBaseUrl,
  configureAuthorizationHeader,
  configureWithCredentials,
  clearAuthorizationHeader,
};

export { isBrowser, isElectron, isLinux, isMac, isMobile, isWindows };
