import useSWR, { mutate as globalMutate } from 'swr';
import { apiRequest, fetcher } from '../lib';
import { ThreadableContext, getThreadsUrl } from './thread';

export const buildRepositoryContentsMutate = (
  threadableId?: string,
  threadId?: string,
  id?: string
) => {
  const url = getRepositoryContentsUrl(threadableId, threadId, id);
  const show_url = id && getRepositoryContentsUrl(threadableId, threadId, id);

  return () => {
    // mutate thread
    globalMutate(getThreadsUrl(threadableId) + '/' + threadId);
    // mutate repository content
    show_url && globalMutate(show_url);
    return globalMutate(url);
  };
};

export const useRepositoryContents = (
  context?: ThreadableContext,
  threadableId?: string,
  threadId?: string
) => {
  const url = getThreadsUrl(threadableId) + '/' + threadId + '/repository_contents';

  const {
    data: repositoryContent,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    repositoryContents: repositoryContent?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const updateRepositoryContent = (
  context?: ThreadableContext,
  threadableId?: string,
  threadId?: string,
  id?: string,
  {
    setErrors = () => {},
    setIsUpdating = (value: boolean) => {},
    onSuccess = () => {},
    payload = {},
  } = {}
) => {
  const url = getThreadsUrl(threadableId) + '/' + threadId + '/repository_contents/' + id;
  const mutate = buildRepositoryContentsMutate(threadableId, threadId, id);
  return apiRequest(url, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

const getRepositoryContentsUrl = (threadableId?: string, threadId?: string, id?: string) => {
  return (
    getThreadsUrl(threadableId) + '/' + threadId + '/repository_contents' + (id ? '/' + id : '')
  );
};
